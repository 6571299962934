<template>
  <b-card header="Add Notification">
    <validation-observer
      v-slot="{ handleSubmit }"
    >
      <b-form @submit.prevent="handleSubmit(submitNotification)">

        <common-notification-fields :common-notification.sync="commonNotification" />

        <b-form-group
          label="Type"
          class="my-2"
        >
          <b-form-radio-group v-model="targetedAudience">
            <b-form-radio
              name="targeted-audience"
              value="All Users"
            >
              All Users
            </b-form-radio>
            <b-form-radio
              name="targeted-audience"
              value="entityUsers"
            >
              Islamic Organization
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>

        <b-form-row v-if="targetedAudience === 'entityUsers'">
          <b-col md="6">
            <inpo-select
              rules="required"
              :is-all-entities-disabled="true"
              label="Entity"
              :inpo-id.sync="entityNotification.entity"
            />
          </b-col>

          <b-col
            cols="12"
            class="my-1"
          >
            <relation-type-checkbox
              rules="required"
              :relation-type.sync="entityNotification.relation_type"
            />
          </b-col>
        </b-form-row>

        <b-row>
          <b-col>
            <back />
          </b-col>
          <b-col class="text-right">
            <submit-button
              v-bind="{
                submit:addNotification,
                handleSubmit}"
            />
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>
<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import Back from '@/common/components/common/FormInputs/Back.vue'
import InpoSelect from '@/common/components/common/FormInputs/InpoSelect.vue'
import CommonNotificationFields from '@/common/components/Notifications/CommonNotificationFields.vue'
import SubmitButton from '@/common/components/common/FormInputs/SubmitButton.vue'
import RelationTypeCheckbox from '@/common/components/Notifications/RelationTypeCheckbox.vue'

export default {
  name: 'AddNotification',
  components: {
    Back,
    InpoSelect,
    CommonNotificationFields,
    SubmitButton,
    RelationTypeCheckbox,
  },
  data() {
    return {
      targetedAudience: 'All Users',
      commonNotification: {
        title: '',
        body: '',
        sendsAt: this.$moment().format('YYYY-MM-DD HH:mm'),
        sendsImmediately: 0,
      },
      entityNotification: {
        entity: '',
        relation_type: [],
      },
    }
  },
  setup() {
    const { successfulOperationAlert } = handleAlerts()
    const {
      getValidationState,
    } = formValidation()
    return {
      successfulOperationAlert, getValidationState,
    }
  },
  methods: {
    addNotification() {
      if (this.targetedAudience === 'All Users') {
        return this.sendNotificationToAllUser()
      }
      return this.sendNotificationToEntityUsers()
    },

    sendNotificationToAllUser() {
      return this.$portalUsers.post('/internalops/notification/send/all-users', this.commonNotification).then(() => {
        this.successfulOperationAlert('Notification is added successfully')
        this.redirectToList()
      })
    },
    sendNotificationToEntityUsers() {
      return this.$portalUsers.post('/internalops/notifications/send/entity', {
        ...this.commonNotification,
        entity: this.entityNotification.entity,
        relation_type: this.parseRelationType(),
      }).then(() => {
        this.successfulOperationAlert('Notification is added successfully')
        this.redirectToList()
      })
    },
    redirectToList() {
      setTimeout(() => {
        this.$router.push({ name: 'list-notifications' })
      }, 2000)
    },
    parseRelationType() {
      return this.entityNotification.relation_type.length === 1 ? this.entityNotification.relation_type[0] : 'all'
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
